@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

@font-face {
  font-family: "Helvetica-Medium"; /*Can be any text*/
  src: local("Helvetica Neue"),
    url("./assets/fonts/HelveticaNeue/HelveticaNeueMedium.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica"; /*Can be any text*/
  src: local("Helvetica Neue"),
    url("./assets/fonts/HelveticaNeue/HelveticaNeueLight.ttf")
      format("truetype");
}

body {
  margin: 0;
  font-family: "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

::-webkit-scrollbar {
  background-color: #1a5be022;
  width: 5px;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(26, 92, 224, 0.559);
  border-radius: 5px;
}

.bn-container .bn-editor {
  padding: 8px;
  margin: 0px;
}

@media (width < 900px) {
  .bn-block-content[data-content-type="heading"][data-level="1"] * {
    font-size: 30px !important;
    margin: 0px;
    padding: 0px;
  }
  .bn-block-content[data-content-type="heading"][data-level="2"] * {
    font-size: 24px;
  }
  .bn-block-content[data-content-type="heading"][data-level="3"] * {
    font-size: 18px;
  }
}
